import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Confetti from "react-confetti";

const CongratulationPage = () => {
  const [showConfetti, setShowConfetti] = useState(true);
  const [ribbons, setRibbons] = useState([]);
  const navigate = useNavigate();
  const colors = ["#ff5733", "#33ff57", "#3357ff", "#ff33e6", "#ffdb33"];


  useEffect(() => {
    const interval = setInterval(() => {
      setRibbons((prev) => [
        ...prev,
        { id: Math.random(), color: colors[Math.floor(Math.random() * colors.length)] }
      ]);
    }, 500);

    setTimeout(() => clearInterval(interval), 5000);
    setTimeout(() => {
      navigate('/')
    }, 7000)
  }, []);

  return (
    <div className="fixed top-0 left-0 z-[1000] w-full h-[970px] flex flex-col items-center justify-center bg-white mx-auto max-w-full overflow-hidden">
      {showConfetti && (
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
          <Confetti numberOfPieces={500} recycle={false} width={window.innerWidth} height={window.innerHeight} />
        </div>
      )}

      <motion.h1
        className="text-6xl font-bold text-black"
        animate={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 1.5, repeat: Infinity }}
      >
        🎉 Welcome! 🎉
      </motion.h1>

      <p className="text-2xl text-gray-700 mt-1 text-center">Now, Your the Part of MLM.</p>

      <div className="absolute w-full h-full top-0 left-0 overflow-hidden">
        {ribbons.map((ribbon) => (
          <motion.div
            key={ribbon.id}
            className="w-6 h-12 absolute"
            style={{ backgroundColor: ribbon.color, clipPath: "polygon(50% 0%, 70% 30%, 100% 30%, 75% 50%, 85% 80%, 50% 60%, 15% 80%, 25% 50%, 0% 30%, 30% 30%)" }}
            initial={{ y: "100vh", x: Math.random() * window.innerWidth, rotate: 0 }}
            animate={{ y: "-10vh", rotate: [0, 360] }}
            transition={{ duration: 3, ease: "easeOut" }}
          />
        ))}
      </div>
    </div>
  );
};

export default CongratulationPage;
