import { useState } from "react";
import UserAccount from "./UserAccount";
function YourWallet() {
     const [userAccount, setUserAccount] = useState(true);
     
    return (
        <div className="flex gap-4">
              <div>{userAccount ? <UserAccount /> : null}</div>
       
        <div className="p-6 w-[100%] bg-gray-100 min-h-screen flex justify-center">
            <div className="w-full max-w-3xl">
                <div className="flex gap-4 mb-6">
                    <div className="bg-white shadow-lg p-4 rounded-lg flex-1 flex items-center justify-between">
                        <div>
                            <h2 className="text-lg font-semibold">Total Earning :</h2>
                            <p className="text-2xl font-bold">Rs. 5000.00</p>
                        </div>
                        <span className="text-4xl">💰📈</span>
                    </div>
                    <div className="bg-white shadow-lg p-4 rounded-lg flex-1 flex items-center justify-between">
                        <div>
                            <h2 className="text-lg font-semibold">Total Coins :</h2>
                            <p className="text-2xl font-bold">45 coins</p>
                        </div>
                        <span className="text-4xl">💰</span>
                    </div>
                </div>
                <div className="bg-white shadow-lg p-4 rounded-lg">
                    <h2 className="text-lg font-semibold mb-2">Transaction History :</h2>
                    <div className="bg-gray-200 shadow-xl p-4 rounded-lg mb-4">
                        <p>
                            Coins will be credited to your wallet after 3-4 business days from the delivery of the product.
                            This process ensures the verification of the delivery and confirmation of the order's completion.
                        </p>
                    </div>
                    <p className="text-gray-700 font-medium pt-8">Expire Coins</p>
                    <p className="text-gray-500">Debited on 01 Dec 2024</p>
                </div>
            </div>
        </div>
        </div>
    );
}

export default YourWallet;
