import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function PaymentCard() {
  const navigate=useNavigate();

function handleSkip(){
    console.log("hello is world!");
    navigate('/')
  }
   async function handlePay(){
    console.log('hello is working')
      try{
      
        navigate('/welcome_congratulations')
      }catch(err){

        console.error('Payment failed', err);
      }
    }


    return (
        <div className="h-[100%] bg-[#000]">
        <div className="z-[1000] border-2 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-[#edebebfc] h-[100%] w-[100%] flex justify-center items-center">
      <div className="flex items-center justify-center ">
        <div className="relative w-96 p-8 bg-white shadow-2xl rounded-xl text-center border border-gray-300 overflow-hidden transform transition-all duration-300 hover:scale-105">
          <div className="absolute -top-10 -right-10 w-32 h-32 bg-purple-500 rounded-full opacity-20"></div>
          <div className="absolute -bottom-10 -left-10 w-32 h-32 bg-indigo-500 rounded-full opacity-20"></div>
          <div className="relative z-10 flex flex-col items-center mb-6">
            <div className="w-20 h-20 bg-purple-500 rounded-full flex items-center justify-center shadow-lg">
              <img
                src="https://m.media-amazon.com/images/S/stores-image-uploads-na-prod/6/AmazonStores/ATVPDKIKX0DER/1801cf91b2805c36abff373d2ab9d5ec.w600.h600._RO299,1,0,0,0,0,0,0,0,0,15_FMpng_.png" // Replace with the correct shopping cart icon path
                alt="Shopping Cart"
                className="w-14 h-14"
              />
            </div>
            <h2 className="mt-4 text-xl font-bold text-gray-800">Unlock MLM Benefits</h2>
            <p className="text-gray-600 text-base mt-2 px-4 leading-relaxed">
              Be a part of <span className="font-bold text-purple-700">MLM</span> by paying <span className="font-bold text-purple-700">₹500</span>.
            </p>
            <div className="w-full bg-gray-100 p-4 rounded-lg mt-4">
              <p className="text-gray-600">Amount</p>
              <h3 className="text-2xl font-bold text-purple-600">₹500</h3>
            </div>
          </div>
          <button onClick={handlePay} className="mt-6 w-full bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white py-3 rounded-lg text-lg font-semibold shadow-md transition-transform transform hover:scale-105">
            Proceed to Pay
          </button>
          <div className="mt-4 text-xs text-gray-500 mb-[10px]">Secure payment with multiple options available</div>
          <div className="tracking-[1px] text-[1.1rem] pb-[2px] w-fit m-auto border-b-2 border-[#B449D5] cursor-pointer hover:text-[#B449D5]"  onClick={handleSkip} >Skip</div>
        </div>
      </div>
      </div>
      </div>
    );
}