import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import MLMPayment from './app/components/Payment'
import Navbar from "./app/components/Navbar";
import NavUpper from "./app/components/NavUpper";
import Home from "./app/pages/Home";
// import NavbarTwo from"./app/components/NavbarTwo";
import About from "./app/components/About";
import Cart from "./app/components/Cart";
import ContactUs from "./app/components/ContactUs";
import Footer from "./app/components/Footer";
import Login from "./app/components/Login";
import OurStores from "./app/components/OurStores";
import ProductDetail from "./app/components/ProductDetail";
import ProductList from "./app/components/ProductList";
import SignUp from "./app/components/SignUp";
import StoreDetails from "./app/components/StoreDetails";
import WishList from "./app/components/WishList";
// import AddressForm from './app/components/AddressForm';
import AboutUs from "./app/components/AboutUs";
import BuyNowOrderSummary from "./app/components/BuyNowOrderSummary";
import Disclaimer from "./app/components/Discalimer";
import InternetConnectionChecker from "./app/components/InternetConnectionChecker";
import NavBarSearchMobile from "./app/components/NavBarSearchMobile";
import OrderSuccessPage from "./app/components/OrderSuccessPage";
import OrderSummary from "./app/components/OrderSummary";
import PrivacyPolicy from "./app/components/PrivacyAndPolicy";
import ProductDetails from "./app/components/ProductDetails";
import RefundAndCancellation from "./app/components/RefundAndCancellation";
import ShippingReturns from "./app/components/ShippingAndReturn";
import SubCategoryListing from "./app/components/SubCategoryListing";
import SubCategoryListingProduct from "./app/components/SubCategoryListingProduct";
import TermsAndConitions from "./app/components/TermsAndConditions";
import Wallet from "./app/components/Wallet";
import MyOrders from "./app/pages/UserAccount/MyOrders";
import OrderDetail from "./app/pages/UserAccount/OrderDetail";
import ProfileInformation from "./app/pages/UserAccount/ProfileInformation";
import Teams from "./app/pages/UserAccount/Teams";
import UserAccount from "./app/pages/UserAccount/UserAccount";
import UserAddresses from "./app/pages/UserAccount/UserAddresses";
import UserAddressForm from "./app/pages/UserAccount/UserAddressForm";
import UserEditAddress from "./app/pages/UserAccount/UserEditAddress";
import Welcome from "./app/components/Welcome";
// import NavbarFlipkart from './app/components/NavbarFlipkart';
import YourLevel from "./app/pages/UserAccount/YourLevel";
import YourWallet from "./app/pages/UserAccount/YourWallet";
import Payment from "./app/pages/UserAccount/Payment";

export default function App() {
  return (
    <Router>
     
      <InternetConnectionChecker />
      {/* <NavbarFlipkart/> */}
      <NavUpper />
      <Navbar />
      <NavBarSearchMobile />

      {/* <NavbarTwo/> */}
      <Routes>
         
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />

        <Route path="/login-user" element={<Login />} />
        <Route path="/signup-user" element={<SignUp />} />
        <Route path="/account" element={<UserAccount />} />
        <Route
          path="/account/account_details"
          element={<ProfileInformation />}
        />
        <Route path="/account/addresses" element={<UserAddresses />} />
        <Route path="/account/:id" element={<UserEditAddress />} />
        {/* <Route path="/account/editAddress_:id" element={<UserEditAddresses/>} />  */}
        <Route path="/ourstores" element={<OurStores />} />
        <Route path="/ourstores/:id" element={<StoreDetails />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/category/:id" element={<SubCategoryListing />} />
        <Route path="/account/newaddress-form" element={<UserAddressForm />} />
        <Route path="/account/wishlist-products" element={<WishList />} />
        <Route path="/products" element={<ProductList />} />
        <Route path="/products/:id" element={<ProductDetail />} />
        <Route path="/account/myorders" element={<MyOrders />} />
        <Route path="/orders/:id" element={<OrderDetail />} />
        <Route
          path="/subcategory/products/:id"
          element={<SubCategoryListingProduct />}
        />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/teams" element={<Teams />} />

        {/* /buySummary/${product.id} */}

        <Route path="/product/details" element={<ProductDetails />} />

        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/shipping_returns" element={<ShippingReturns />} />
        <Route path="/terms_conditions" element={<TermsAndConitions />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route
          path="/refund_cancellation"
          element={<RefundAndCancellation />}
        />
        <Route path="/order_summary" element={<OrderSummary />} />
        <Route path="/buySummary/:id" element={<BuyNowOrderSummary />} />
        <Route path="/order_sucess_page" element={<OrderSuccessPage />} />
        <Route path="/welcome_congratulations" element={<Welcome />} />
        {/* <Route path="/mlmpayment" element={<MLMPayment />} /> */}
        <Route path="/account/your-level" element={<YourLevel />} />
        <Route path="/account/your-wallet" element={<YourWallet />}/>
        <Route path="/payment" element={<Payment/>}/>
       
      </Routes>
      <Footer />
    </Router>
  );
}
