import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import PaymentMLM from "./Payment"

const SignUp = () => {
  const navigate = useNavigate();
  const [paymentCard,setPaymentCard]=useState(false);
  const [signupData, setSignupData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    otp: "",
    referral_code: "",
  });
  const [referralCode, setReferralCode] = useState("");
  const [ref, setRef] = useState("");
  const [iv, setIv] = useState("");
  // const [refral, setRefral] = useState([]);

  const [otpSent, setOtpSent] = useState(false);

  useEffect(() => {
    async function getReferralCode() {
      const queryParams = new URLSearchParams(window.location.search);
      const ref = queryParams.get("ref"); // Get the 'ref' parameter
      const ivParam = queryParams.get("iv"); // Get the 'iv' parameter
      setRef(ref);
      setIv(ivParam);
      if (ref != undefined && ivParam != undefined) {
        console.log(ref);
        console.log(ivParam);
        const response = await axios.get(`
          https://ourmicrolifebackend.onrender.com/api/refferal-code/has-ref/${ref}/iv/${ivParam}`);
        setReferralCode(response.data.data);
      }
    }

    getReferralCode();
  }, []);
  console.log("referral code ---> ", referralCode);
  const resendOTP = async (mobile_number) => {
    try {
      const response = await axios.post(
        `
        ${process.env.REACT_APP_PUBLIC_API_BASE_URL}user/resend-otp`,
        {
          mobile_number: signupData.mobile_number,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("OTP Resent Successful:", response.data);
      // Assuming the API returns a success message or status indicating OTP should be sent
      setOtpSent(true);
      toast.success("OTP has been resent to your mobile number.");
    } catch (error) {
      console.error(
        "Error in resending OTP:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleResendOTP = () => {
    console.log(signupData);
    const { mobile_number } = signupData;
    if (mobile_number) {
      resendOTP(mobile_number);
    } else {
      toast.error("Mobile No Not Found");
    }
  };

  const signupUser = async (signupData) => {
    try {
      console.log("Sending signup data:", signupData);
      const response = await axios.post(
        `
        ${process.env.REACT_APP_PUBLIC_API_BASE_URL}user/signup`,
        signupData
      );
      console.log("Signup successful:", response);
      setOtpSent(true);
      toast.success("OTP has been sent to your mobile number.");
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error(
        "Error during signup. Try different email or mobile number.",
        error.response ? error.response.data : error.message
      );
    }
  };

  const verifyOtp = async (otp) => {
    try {
      const { otp, mobile_number } = signupData;
      console.log("Verifying OTP:", otp);
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}user/verify-otp`,
        { otp, mobile_number }
      );
      console.log("OTP verification successful:", response);
      const userid = response.data.user.id;
      toast.success("OTP verified successfully!");
      console.log("payment card ---> ",paymentCard)
      setPaymentCard(true);
      if (referralCode == "") {
        const responseLink = await axios.post(`
          ${process.env.REACT_APP_NODE}refferal/user/${userid}`);
      } else if (ref != null && iv != null) {
        console.log(ref, " ", iv);
        const response = await axios.post(
          `${process.env.REACT_APP_NODE}signup-user/${userid}/?ref=${ref}&iv=${iv}`
        );
        console.log(response);
      } else if (referralCode != "") {
        console.log("referral code is ---> ", referralCode);
        const response = await axios.post(
          `${process.env.REACT_APP_NODE}/signup-user/referral-code`,
          {
            referral_code: referralCode,
          }
        );
        console.log("ref response ->", response);
      }

      
     
    } catch (error) {
      console.error(
        "Error during OTP verification:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleSignup = () => {
    const { first_name, last_name, email, mobile_number } = signupData;
    if (first_name && last_name && email && mobile_number) {
      signupUser(signupData);
    } else {
      toast.warning("Please fill all fields");
    }
  };

  const handleOtpVerification = () => {
    if (signupData.otp) {
      verifyOtp(signupData.otp);
    } else {
      toast.success("Please enter the OTP sent to your mobile number");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "referral_code") {
      setReferralCode(value);
    } else if (name === "mobile_number") {
      if (/^\d*$/.test(value) && value.length <= 10) {
        // Limit to 10 digits
        setSignupData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setSignupData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (<>
    {!paymentCard? <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-[100%]">
    <div className="flex items-center justify-center min-h-screen p-6">
      <div className="flex flex-col md:flex-row w-full max-w-4xl bg-white shadow-2xl rounded-lg overflow-hidden">
        {/* Left Side - Image */}
        <div className="hidden md:flex w-1/2 bg-cover bg-center" style={{ backgroundImage: 'url("https://cdn.pixabay.com/photo/2021/11/22/20/20/online-6817350_1280.jpg")' }}></div>
        
        {/* Right Side - Form */}
        <div className="w-full md:w-1/2 p-10 flex flex-col justify-center">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Create Your Account</h2>
          <div className="flex gap-4">
            <input type="text" name="first_name" value={signupData.first_name} onChange={handleChange} placeholder="First Name" className="w-1/2 p-3 border rounded-lg focus:ring-2 focus:ring-blue-400" />
            <input type="text" name="last_name" value={signupData.last_name} onChange={handleChange} placeholder="Last Name" className="w-1/2 p-3 border rounded-lg focus:ring-2 focus:ring-blue-400" />
          </div>
          <input type="email" name="email" value={signupData.email} onChange={handleChange} placeholder="Email" className="w-full p-3 mt-4 border rounded-lg focus:ring-2 focus:ring-blue-400" />
          <input type="text" name="mobile_number" value={signupData.mobile_number} onChange={handleChange} placeholder="Mobile Number" className="w-full p-3 mt-4 border rounded-lg focus:ring-2 focus:ring-blue-400" />
          <input type="text" name="referral_code" value={referralCode} onChange={handleChange} placeholder="Referral Code (Optional)" className="w-full p-3 mt-4 border rounded-lg focus:ring-2 focus:ring-blue-400" />
          {!otpSent ? (
            <button onClick={handleSignup} className="w-full mt-6 bg-blue-600 text-white p-3 rounded-lg text-lg font-semibold hover:bg-blue-700 shadow-lg transition">Send OTP</button>
          ) : (
            <>
              <input type="text" name="otp" value={signupData.otp} onChange={handleChange} placeholder="Enter OTP" className="w-full p-3 mt-4 border rounded-lg focus:ring-2 focus:ring-blue-400" />
              <button onClick={handleOtpVerification} className="w-full mt-6 bg-green-500 text-white p-3 rounded-lg text-lg font-semibold hover:bg-green-600 shadow-lg transition">Verify OTP</button>
            </>
          )}
          <p className="mt-6 text-center text-gray-600">Already have an account? <Link to="/login-user" className="text-blue-500 hover:underline font-semibold">Login</Link></p>
        </div>
      </div>
    </div>
    </div>:<PaymentMLM/>}
   
    </>);
};

export default SignUp;
