import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GiTwoCoins } from "react-icons/gi";

import { useNavigate, useParams,useLocation } from "react-router-dom";
import { addproduct, userAddresses } from "./../../redux/action";

const BuyNowOrderSummary = () => {
  // Static cart items
  const location = useLocation();
 console.log("location is --->  ",location);
const {buyId } = location?.state || 356;
console.log("buy id is visible",buyId)
  const [product, setProduct] = useState({});
  const [items,setItems]=useState({
    "productName":"loading....",
    "color":"loading....",
    "configuration":"loading....",
    "price":"loading....",
    "quantity":"loading....",
    "image":"loading....",
  })
  const [productName, setProductName] = useState("");
  const [Imagefeature, setImage] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [addresses, setaddresses] = useState([]);
  const [selectedAddressbuyId, setSelectedAddressbuyId] = useState(-1);
  const [payementType, setPayementType] = useState("cod");
  const [productPrice, setProductPrice] = useState([]);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [buyImage, setbuyImage] = useState([]);
  const [productbuyId, setProductbuyId] = useState(0);
  const [VendorbuyId, setVendorbuyId] = useState(0);
  const [foundProduct, setFoundProduct] = useState([]);
  const [isProduct, setIsProduct] = useState(false);
  const [price, setPrice] = useState([]);
  const [old_price, setOldPrice] = useState([]);
  const [activeSection, setActiveSection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successOrder, setSuccessOrder] = useState(false);
  const [coin, setCoin] = useState(0);
  const [formData, setFormData] = useState({
    coins: 0,
    products: [{ heading: "", coin: 0 }],
  });

  
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  

  const navigate=useNavigate();
  const userProfile = useSelector((state) => state.userProfile);

  // const address = useSelector((state) => state.userAddresses);
  // console.log("The redux address is: ", address);

  const products = useSelector((state) => state.products);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(addproduct());
  }, [dispatch]);

  console.log("product redux", products);
   console.log("items---> ",items);
  useEffect(() => {

    const fetchBuyNow = async () => {
      try {
        console.log("buy is now working")
         
        const response = await axios.get(
          `${process.env.REACT_APP_NODE}user/order/buyOrders/${buyId}`
        );
        console.log("data buy",  response.data.data[0]);
        const data= response.data.data[0]
        setItems({
          "productName":data.product_name,
    "color":data.color,
    "configuration":data.size,
    "price":data.price,
    "quantity":data.quantity,
    "image":data.image_url,
        })
        
        setIsProduct(response.data);
        setColor(response.data.color);
        setImage(response.data.image_url);
        setProductName(response.data.product_name);
        setSize(response.data.size);

        const coin = response.data.coin;
        setCoin(response.data.coin);
        setPrice(response.data.price);
        setOldPrice(response.data.old_price);
        setVendorbuyId(response.data.vendor_buyId);
        const productbuyId = response.data.products;

        setProductbuyId(productbuyId);

        console.log("total coins", typeof coin);
        // setFormData((prevData) => ({
        //   ...prevData,
        //   coins: coin,
        // }));
        const productName = response.data.product_name;
        const updatedProducts = {
          heading: productName,
          coin: coin,
        };

        console.log("products which i have to send ", updatedProducts);

        // Update formData with totalCoins and updatedProducts
        setFormData((prevData) => ({
          ...prevData,
          coins: coin,
          products: [updatedProducts],
        }));
        console.log("products", products);
        const product = products.filter((item) => item.buyId === productbuyId);
        console.log("product", product);
        setProduct(product[0]);
        const ProductPrices = product[0].prices.filter(
          (item) => item.color_name === response.data.color
        );
        console.log("product", ProductPrices[0]);
        setProductPrice(ProductPrices[0]);
        const image = ProductPrices[0].images[0].image_path;
        console.log("image", image);
        setbuyImage(image);
        // setFormData(setOrderObj);
      } catch (error) {
        console.error("Error fetching buy now data:", error);
      }
    };
    if(buyId) fetchBuyNow();

    const userAddresses = async () => {
      console.log("m here");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_API_BASE_URL}user/shipping-addresses/user_id_${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setaddresses(response.data.addresses);
        setSelectedAddressbuyId(response.data.addresses[0].buyId);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };
    
  
    userAddresses();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, ['',buyId]);

  const fetchUserData = () => async (dispatch) => {
    // await dispatch(setUserProfile());
    await dispatch(userAddresses());
  };

  // Then use a single `useEffect`
  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  const handleAddressSelect = (e) => {
    const addressbuyId = e.target.value;
    setSelectedAddress(addressbuyId);
  };

  const handlePaymentSelect = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  // Function to handle placing the order
  const handlePlaceOrder = async () => {
    const buyId = localStorage.getItem('buybuyId');
    console.log("buyId is ",buyId)
    if (selectedAddressbuyId === -1) {
      // alert("Please select a shipping address before placing the order.");
      toast.success(
        "Please select a shipping address before placing the order."
      );
      return;
    }
    const orderItem = [
      {
        product_buyId: productbuyId,
        size: size,
        color: color,
        sales_price: price,
        old_price: old_price,
        vendor_buyId: VendorbuyId,
        total_price: price,
        quantity: 1,
      },
    ];

    const orderData = {
      total_items: 1, // Set as 1 since there’s only one item
      payment_type: payementType,
      payment_status: "PENDING",
      total_amount: price,
      net_amount: price,
      shipping_charges: 0.0, // Static value, adjust as needed
      status: "placed",
      user_buyId: userId, // Replace with the dynamic user buyId
      shipping_address_buyId: selectedAddressbuyId, // Replace with the dynamic shipping address buyId
      order_items: orderItem,

      // Send the single order item
    };
    try {
      console.log("order send", orderData);
      if (selectedAddressbuyId && payementType) {
        const response = await axios.post(
          `${process.env.REACT_APP_PUBLIC_API_BASE_URL}cart/orders_cart`,
          orderData
        );
        console.log("response", response.data);
        if (response.data.success === true) {
          console.log("im here");
          // If order creation is successful, update successOrder state
          setSuccessOrder(true);
          const deleteResponse = await axios.delete(
            `${process.env.REACT_APP_PUBLIC_API_BASE_URL}buyorders/${buyId}`
          );
          console.log("delete after place order", deleteResponse.data);

          console.log("formData", formData);
          const response = await axios.post(
            `${process.env.REACT_APP_NODE}coins/product/addCoins/${userId}`,
            formData
          );
          console.log("response for coin", response.data);

          navigate("/order_sucess_page");
          // setOrderSuccess(false);
          setSuccessOrder(false);
        }
        // Proceed with delete API only if the order was successfully placed
        if (successOrder) {
          console.log("deleted");
        }

        // Navigate to the 'my orders' page
        // navigate("/order_sucess_page");
      }
    } catch (error) {
      console.error("Error in placing order or deleting order:", error);
    }
  };

  const handleaddAddress = () => {
    navigate("/account/newaddress-form");
  };

  return (
    <div className="container mx-auto grbuyId grbuyId-cols-1 md:grbuyId-cols-3 gap-6 mt-8">
      {/* Left Section - Address, Cart Items, Payment Options */}
      <div className="md:col-span-2 bg-white p-5 rounded-lg shadow-lg">
        {/* Cart Items */}
        {isProduct ? (
          <div className={`mb-6 shadow-lg`}>
            <h2 className="text-xl font-bold mb-2 cursor-pointer  pt-3 pb-3 pl-3 text-purple-500">
              Your Item
            </h2>

            <ul className="space-y-4 pl-5 pr-5 pb-2">
              <li className="flex justify-between items-center border p-2">
                <div className="flex">
                  <div>
                    <img
                      className="w-20 h-20 object-cover"
                      src={`${process.env.REACT_APP_PUBLIC_PRODUCT_IMAGES_BASE_URL}/storage/app/public/${items.image}`}
                    />
                  </div>
                  <div className="ml-4">
                    <p className="font-semibold text-lg">{items.productName}</p>
                    <p className="text-gray-600">Color: {items.color}</p>
                    <p className="text-gray-600">Configuration: {items.configuration}</p>
                  </div>
                </div>
                <div></div>
                <div>
                  <div className="flex items-center">
                    <p className="text-gray-700 mr-2">Price:{items.price}</p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-gray-700 mr-2">Quantity:</p>
                    <p className="text-green-600 font-semibold">1</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}

        {/* Payment Options */}
        <div
          className={`mb-6 shadow-lg ${
            activeSection === "address" ? "bg-gray-100" : ""
          }`}
        >
          <h2
            className="text-xl font-bold mb-2 cursor-pointer bg-white pt-3 pb-3 pl-3 text-purple-500"
            onClick={() => toggleSection("address")}
          >
            Choose Delivery Address
            <span className="ml-2 text-purple-500">
              {activeSection === "address" ? "-" : "+"}
            </span>
          </h2>
          {activeSection === "address" && (
            <div className="space-y-4 pl-8 pr-5 ">
              {addresses.map((address, index) => (
                <div
                  key={address.buyId}
                  className="flex items-center space-x-3  border-b-2 pb-2"
                >
                  <input
                    type="radio"
                    buyId={`address-${address.buyId}`}
                    name="address"
                    value={address.buyId}
                    onChange={handleAddressSelect}
                    onClick={() => {
                      setSelectedAddressIndex(index);
                      setSelectedAddressbuyId(address.buyId);
                    }}
                    checked={index === selectedAddressIndex}
                  />
                  <label
                    htmlFor={`address-${address.buyId}`}
                    className="ml-2  mr-5 w-full"
                  >
                    <div className="flex justify-between mb-3">
                      <div className="font-semibold text-lg text-gray-700">
                        {address.full_name}
                      </div>
                      <div className="text-sm text-gray-600">
                        {address.mobile_number}
                      </div>
                    </div>
                    <div className="text-sm text-gray-600 mb-2">
                      {address.full_address} - {address.near_by_address},{" "}
                      {address.city}, {address.state}
                    </div>
                    <div className="text-sm text-gray-600">
                      Pin Code: {address.pin_code}
                    </div>
                  </label>
                </div>
              ))}
              <div className="pb-2 " onClick={() => handleaddAddress()}>
                <div className="bg-purple-500 flex justify-center items-center text-white lg:pt-3 lg:pb-3 sm:pb-2 sm:pt-2">
                  Add Address
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Payment Options */}
        <div
          className={`mb-6 shadow-lg ${
            activeSection === "payment" ? "bg-gray-100" : ""
          }`}
        >
          <h2
            className="text-xl font-bold mb-2 cursor-pointer bg-white pt-3 pb-3 pl-3 text-purple-500"
            onClick={() => toggleSection("payment")}
          >
            Payment Options
            <span className="ml-2 text-purple-500">
              {activeSection === "payment" ? "-" : "+"}
            </span>
          </h2>
          {activeSection === "payment" && (
            <div className="space-y-4 pl-8 pb-3">
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  buyId="upi"
                  name="payment"
                  value="upi"
                  onChange={() => {
                    handlePaymentSelect("upi");
                    setPayementType("online_mode");
                  }}
                  checked={payementType === "online_mode"}
                />
                <label htmlFor="upi" className="ml-2">
                  Online Mode
                </label>
              </div>
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  buyId="cod"
                  name="payment"
                  value="cod"
                  onChange={() => {
                    handlePaymentSelect("COD");
                    setPayementType("cod");
                  }}
                  checked={payementType === "cod"}
                />
                <label htmlFor="cod" className="ml-2">
                  Cash on Delivery (COD)
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Right Section - Total Summary */}
      <div className="bg-white sticky top-20 p-6 rounded-lg shadow-lg sticky top-0">
        <h2 className="text-xl font-bold mb-4">Order Summary</h2>
        <div className="space-y-2">
          <div className="flex justify-between">
            <p>Total Items</p>
            {/* <p>{totalItems}</p> */}
            <p>1</p>
          </div>
          <div className="flex justify-between">
            <p>Total Price</p>
            <p> {price}</p>

            {/* <p className="font-semibold text-blue-500">₹{product.sale_price}</p> */}
          </div>
          <div className="flex justify-between">
            <p>Total Coins</p>
            <div className="flex gap-2 items-center text-orange-400">
              <GiTwoCoins /> <p className="font-semibold ">{coin}</p>
            </div>
          </div>
        </div>
        <hr className="my-4" />
        <button
          className="w-full py-3 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition duration-300"
          onClick={() => handlePlaceOrder()}
          disabled={isLoading}
        >
          {isLoading ? "Placing Order..." : "Place Order"}
        </button>
        {successOrder && (
          <div className="mt-4 text-green-500 font-bold">
            Your order has been placed successfully!
          </div>
        )}
      </div>
    </div>
  );
};

export default BuyNowOrderSummary;
