import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaTag } from "react-icons/fa";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

import { useNavigate } from "react-router-dom";
import banner from "../images/StoreBanner.jpg";
import logo from "../images/profiledummy.png";

import { MdIosShare } from "react-icons/md";
import { useParams } from "react-router-dom";
const StoreDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [shop, setShop] = useState(null);
  const [products, setShopProduct] = useState([]);

  const fetchStore = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE}vendor/stores/Details/${id}`
      );
      console.log(response.data.data);
      setShop(response.data.data);
      const vendor_id = response.data.data.vendor_id;

      const responseProduct = await axios.get(
        `${process.env.REACT_APP_NODE}vendor/stores/products/${vendor_id}`
      );
      console.log(responseProduct.data.data);
      setShopProduct(
        responseProduct.data.data.reverse().map((product) => ({
          ...product,
          sanitizedDescription: DOMPurify.sanitize(
            product.description.slice(0, 50)
          ),
        }))
      );
    } catch (error) {
      console.error("Error in fetching stores", error);
    }
  };

  useEffect(() => {
    fetchStore();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [id]);

  if (!shop) {
    return <p className="text-center text-xl font-medium mt-10">Loading...</p>;
  }
  const handleShare = (shop) => {
    const shareData = {
      title: shop.storeName,
      text: `Check out this shop: ${shop.storeName} (${shop.storeCategory})`,
      url: window.location.href, // current page URL
    };

    // Use Web Share API if available
    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Shared successfully"))
        .catch((err) => console.error("Error sharing:", err));
    } else {
      // Fallback links for manual sharing
      const emailSubject = `Check out this shop: ${shop.storeName}`;
      const emailBody = `Here's the shop you might like:\n\nShop Name: ${shop.storeName}\nCategory: ${shop.storeCategory}\nAddress: ${shop.storeAddress}\nLink: ${window.location.href}`;
      const whatsappMessage = `Check out this shop: ${shop.storeName}\nCategory: ${shop.storeCategory}\nAddress: ${shop.storeAddress}\nLink: ${window.location.href}`;
      const shareLinks = `
        Email: mailto:?subject=${encodeURIComponent(
          `Check out ${shop.storeName}`
        )}&body=${encodeURIComponent(
        `Here's the link: ${window.location.href}`
      )}
        WhatsApp: https://wa.me/?text=${encodeURIComponent(
          `Check out this shop: ${shop.storeName} - ${window.location.href}`
        )}
      `;
      alert(`Share via:\n${shareLinks}`);
    }
  };
  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`);
  };
  const calculateDiscount = (salePrice, oldPrice) => {
    let saleP = +salePrice;
    let oldP = +oldPrice;
    if (saleP && oldP && oldP > saleP) {
      return Math.round(((oldP - saleP) / oldP) * 100);
    }
    return 0;
  };
  return (
    <div className="">
      {/* Image Section */}
      <div className="relative w-full h-[300px] mb-4 ">
        {/* Main Image */}
        <img
          src={banner}
          alt="Vendor"
          className=" border-2 border-gray-300 shadow-lg object-cover w-full h-full"
        />

        {/* Logo */}
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
          <img
            src={logo}
            alt="Logo"
            className="w-24 h-24 bg-white rounded-full border-2 border-white shadow-lg"
          />
        </div>
      </div>

      {/* Store Info Section */}
      <div className="flex flex-col items-center lg:items-start space-y-6 lg:space-y-8 px-2">
        {/* Shop Name and Category */}

        <div className="flex flex-col lg:flex-row items-center justify-between w-full">
          {/* Shop Name and Shop Category */}
          <div className="">
            {/* Shop Name and Share Icon */}
            <div className="flex items-center ">
              <p className="text-2xl font-bold text-gray-900">
                {shop.storeName}
              </p>
              <button
                className="text-black-800 text-[20px] hover:text-blue-500"
                aria-label="Share"
                onClick={() => handleShare(shop)}
              >
                <MdIosShare className="font-bold ml-2" />
              </button>
            </div>

            <p className="font-semibold text-black-700 text-[15px]">
              Shop Category: {shop.storeCategory}
            </p>
          </div>
        </div>

        {/* Ratings and Description */}
        <div className="text-lg text-gray-500">
          <p className="">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            vulputate, lorem eget vehicula fermentum, purus nisi cursus eros, ut
            gravida ligula mauris eu odio. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Nullam vulputate, lorem eget vehicula
            fermentum, purus nisi cursus eros, ut gravida ligula mauris eu odio.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            vulputate,
          </p>
        </div>

        {/* Contact Section */}
        {/* <div className="p-4 border-t border-b border-gray-200 w-full">
        <address className="not-italic text-gray-700">
          <p className="font-bold">Contact us at:</p>
          <p className="mt-2">
            <a
              href="mailto:contact@example.com"
              className="text-blue-500 hover:underline"
            >
              contact@example.com
            </a>
            <br />
            Phone: (123) 456-7890
            <br />
            123 Example Street
            <br />
            {shop.shop_address}
          </p>
        </address>
      </div> */}
      </div>

      {/* Categories Section */}
      {/* <div className="mt-10">
        <Categories />
      </div> */}
      <div className="flex justify-center text-2xl items-center">
        <p>Our Products</p>
      </div>
      <div className="p-10">
        {products === undefined || products.length === 0 ? (
          <p className="text-center text-gray-600">
            No products found for this store.
          </p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {products?.map((product) => (
              <div
                key={product.id}
                onClick={() => handleProductClick(product.id)}
                className="group relative bg-white shadow-sm border-2 rounded-lg overflow-hidden transform transition-transform duration-700 ease-in-out hover:scale-[1.02]"
              >
                <Link to={`/products/${product.id}`}>
                  <div className="relative">
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_PRODUCT_IMAGES_BASE_URL}/storage/app/public/${product.featured_image}`}
                      alt={product.name}
                      className="object-cover w-full h-48"
                    />
                    <div className="absolute top-1 left-1 bg-[#a855f7] text-white px-2 py-1 rounded-full text-[10px] flex items-center">
                      <FaTag className="mr-1" />
                      {calculateDiscount(product.sale_price, product.old_price)}
                      % OFF
                    </div>
                  </div>
                  <div className="p-4">
                    <p className="text-start text-lg font-semibold mb-2">
                      {product.name.slice(0, 50)}
                    </p>
                    <p
                      className="text-sm text-gray-600 mb-2"
                      dangerouslySetInnerHTML={{
                        __html: product.sanitizedDescription,
                      }}
                    ></p>
                    {/* <div className="text-sm text-gray-600 mb-2 sm:mb-1">
              {renderStars(product.rating)}
            </div> */}
                    <div className="flex items-center space-x-2 mb-2 sm:mb-1">
                      <span className="text-sm font-bold text-green-500">
                        ₹{product.sale_price}
                      </span>
                      <span className="text-sm text-gray-500 line-through">
                        ₹{product.old_price}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreDetail;
