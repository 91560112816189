import axios from "axios";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addproduct, updateTotalQuantity } from "../../redux/action";
import image from "../images/Website.png";
// Samsung7.jpg

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [fetchItems, setFetchItems] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const location = useLocation();

  const products = useSelector((state) => state.products);
  const totalQuantityItem = useSelector((state) => state.cart.totalQuantity);
  const cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  console.log("cartitem:", totalQuantityItem);

  const fetchFirstCampaign = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE}admin/campaign/page/cart/location/1`
      );
      const data = response.data.data;

      if (Array.isArray(data) && data.length > 0) {
        const lastElement = data[data.length - 1]; // Access the last element
        console.log("Last Element:", lastElement);
        setFirst(lastElement); // Set the last element as state
      } else {
        console.log("Data is not an array or is empty");
      }
      console.log("response 1", response.data.data);
    } catch (error) {
      console.error("error in get first campaign", error);
    }
  };
  const fetchSecondCampaign = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE}admin/campaign/page/cart/location/2`
      );
      const data = response.data.data;

      if (Array.isArray(data) && data.length > 0) {
        const lastElement = data[data.length - 1]; // Access the last element
        console.log("Last Element:", lastElement);
        setSecond(lastElement); // Set the last element as state
      } else {
        console.log("Data is not an array or is empty");
      }
      console.log("response 2", response.data.data);
    } catch (error) {
      console.error("error in get first campaign", error);
    }
  };

  const fetchCartItems = async () => {
    setLoading(true);
    setError(null);

    if (!token || !userId) {
      setError("You need to log in to view the cart.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}cart/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use the token to authenticate the request
          },
        }
      );
      console.log(response.data);
      let products = response.data.cart.products;
      setCartItems(products);
      let sum = 0;
      for (let i = 0; i < products.length; i++) {
        sum += parseInt(products[i].pivot.price * products[i].pivot.quantity);
      }
      // console.log("total price ", totalPrice, sum);
      setTotalPrice(sum);
      // console.log("carts items", response.data.cart.products);
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setError("Failed to load cart items.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCartItems();
    fetchFirstCampaign();
    fetchSecondCampaign();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [fetchItems]);

  useEffect(() => {
    dispatch(addproduct());
  }, [dispatch]);
  useEffect(() => {
    dispatch(updateTotalQuantity());
  }, [dispatch]);

  const handleQuantityChange = async (
    id,
    cartProductId,
    vendor_id,
    newQuantity
  ) => {
    if (newQuantity < 1) return; // Prevent decreasing quantity below 1
    // console.log(newQuantity, id, "product id", cartProductId);
    try {
      // Update the quantity via API
      let response = await axios.put(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}cart/${id}/update-product/${cartProductId}`,
        {
          quantity: newQuantity,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedCartItems = cartItems.map((item) => {
        if (item.pivot.id === cartProductId) {
          return {
            ...item,
            pivot: {
              ...item.pivot,
              quantity: newQuantity,
            },
          };
        }
        return item;
      });
      console.log("updated cart", updatedCartItems);

      setCartItems(updatedCartItems);

      // Recalculate total price
      const updatedTotalPrice = updatedCartItems.reduce(
        (sum, item) => sum + item.pivot.price * item.pivot.quantity,
        0
      );
      setTotalPrice(updatedTotalPrice);
      const totalQuantity = updatedCartItems.reduce((sum, item) => {
        return sum + (item.pivot?.quantity || 0);
      }, 0);
      console.log("total cart", totalQuantity);
      dispatch(updateTotalQuantity(totalQuantity));
      // Update the cart items state with the new quantity
    } catch (error) {
      console.error("Error updating quantity:", error);
      setError("Failed to update the quantity.");
    }
  };

  const handleRemoveItem = async (id, cartProductId, vendor_id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}cart/${id}/remove-product/${cartProductId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use the token to authenticate the request
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        setFetchItems(!fetchItems);
        toast.success("Item has been successfully removed from your cart!");
      }
      setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
    } catch (error) {
      console.log("Error in delete Cart", error);
    }
  };

  // navigate to the product detail page with category
  const handleproductDetailpage = (product_id) => {
    navigate(`/products/${product_id}`);
  };

  const handleLogin = () => {
    navigate("/login-user");
  };

  return (
    <div className=" container mx-auto p-6">
      {loading ? (
        <div>
          {[...Array(3)].map((_, index) => (
            <div className="flex items-center justify-between bg-white lg:p-4 mb-4 rounded-lg shadow-md flex-col sm:flex-row gap-10">
              {/* Image Skeleton */}
              <div className="relative">
                <Skeleton width={100} height={100} className="border mr-6 " />
              </div>

              {/* Details Skeleton */}
              <div className="flex flex-col sm:flex-row sm:pl-6 w-full">
                <div className="sm:w-2/3">
                  {/* Product Name */}
                  <Skeleton className="h-6 w-2/3 mb-2" />

                  {/* Color */}
                  <Skeleton className="h-4 w-1/3 mb-2" />

                  {/* Seller */}
                  <Skeleton className="h-4 w-1/2 mb-2" />
                  <Skeleton className="h-4 w-1/2 mb-2" />

                  {/* Price */}
                  <div className="mt-2">
                    <Skeleton className="h-6 w-1/4" />
                  </div>

                  <div className="mt-2">
                    <Skeleton className="h-6 w-1/4" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : error ? (
        <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-auto p-6 text-center mt-10">
          {/* Image */}
          <div className="flex justify-center mb-4">
            <img src={image} alt="Wishlist Icon" className="h-28 w-38" />
          </div>

          {/* Title */}
          <h2 className="text-lg font-semibold text-gray-800 mb-2">
            Missing Cart items?
          </h2>

          {/* Subtitle */}
          <p className="text-gray-600 text-sm mb-4">
            Login to see the items you added previously to your Cart.
          </p>

          {/* Login Button */}
          <button
            onClick={() => handleLogin()} // Call the function here
            className="bg-orange-500 text-white px-6 py-2 rounded-full hover:bg-orange-600 transition"
          >
            Login
          </button>
        </div>
      ) : cartItems.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-50 p-6 shadow-sm">
          {/* Image Section */}
          <img src={image} alt="Empty Cart" className="w-1/2 md:w-1/4 mb-6" />

          {/* Message Section */}
          <p className="text-center text-gray-600 text-lg">
            Your cart is empty.
          </p>

          {/* Link Section */}
          <Link
            to="/products"
            className="mt-4 text-blue-500 underline text-lg hover:text-blue-700"
          >
            Continue Shopping
          </Link>
        </div>
      ) : (
        <div>
          <div className="flex flex-col lg:flex-row gap-6 p-4">
            {/* Left Section */}
            <div className=" sticky top-24 w-[540px] lg:h-[590px] p-6 rounded-lg shadow-md">
              <img
                src={`${process.env.REACT_APP_NODE_CATEGORY}uploads/campaign/${first.image}`}
                alt="Cart Banner"
                className="w-full border h-[550px] object-cover rounded-lg"
              />
            </div>

            {/* Right Section */}
            <div className="w-full ">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {/* Cart Items */}
                <div className="md:col-span-2 lg:col-span-2">
                  {cartItems.map((item) => {
                    const product = {}; // Replace with product lookup logic
                    return (
                      <div
                        key={item.id}
                        className="flex items-center justify-between bg-white lg:p-2 mb-2 rounded-lg shadow-md flex-col sm:flex-row gap-6"
                      >
                        <div
                          onClick={() =>
                            handleproductDetailpage(item.product_id)
                          }
                          className="relative cursor-pointer"
                        >
                          <img
                            className="border w-24 h-20 object-cover"
                            src={`https://ourmicrolife.com/ourmicrolife/storage/app/public/${item.featured_image}`}
                            alt={item.name}
                          />
                        </div>
                        <div className="flex flex-col sm:flex-row sm:pl-4 w-full">
                          <div className="sm:w-2/3">
                            <h2 className="text-lg font-semibold text-gray-800">
                              {item.name}
                            </h2>
                            <p className="text-sm text-gray-500 ">
                              Color: {item.pivot.color}
                            </p>
                            <p className="text-sm text-gray-500 ">
                              size:{item.pivot.size}
                            </p>
                            {/* <p className="text-sm text-gray-500">Seller: {product?.sellerName || "Unknown Seller"}</p> */}
                            <p className="text-[15px] font-semibold text-gray-800 ">
                              ₹{item.pivot.price * item.pivot.quantity}
                            </p>
                            <div className="flex items-center  space-x-2">
                              <button
                                onClick={() =>
                                  handleQuantityChange(
                                    item.pivot.cart_id,
                                    item.pivot.id,
                                    item.vendor_id,
                                    item.pivot.quantity - 1
                                  )
                                }
                                className="px-2 py-0 bg-gray-200 text-gray-800 border rounded hover:bg-gray-300 transition"
                                disabled={item.pivot.quantity <= 1}
                              >
                                -
                              </button>
                              <input
                                type="number"
                                min="1"
                                value={item.pivot.quantity}
                                className=" pl-1 w-7 text-center border rounded "
                              />
                              <button
                                onClick={() =>
                                  handleQuantityChange(
                                    item.pivot.cart_id,
                                    item.pivot.id,
                                    item.vendor_id,
                                    item.pivot.quantity + 1
                                  )
                                }
                                className="px-2 py-0 bg-gray-200 text-gray-800 border rounded hover:bg-gray-300 transition"
                              >
                                +
                              </button>
                            </div>
                            <div className="flex items-center mt-1 space-x-4">
                              <button
                                className="text-blue-500 flex items-center space-x-2 hover:text-blue-700 transition"
                                onClick={() => handleRemoveItem(item.id)}
                              >
                                Save for later
                              </button>
                              <button
                                className="text-red-500 flex items-center space-x-2 hover:text-red-700 transition"
                                onClick={() =>
                                  handleRemoveItem(
                                    item.pivot.cart_id,
                                    item.pivot.id,
                                    item.vendor_id
                                  )
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* Cart Summary */}
                <div className="sticky top-24 bg-white p-6 rounded-lg shadow-md h-[400px] sm:w-full w-[300px] md:w-[250px] lg:w-[300px] ">
                  <h2 className="text-xl font-semibold mb-4">Price Details</h2>
                  <div className="flex justify-between mb-2">
                    <p>Subtotal</p>
                    <p>₹{totalPrice}</p>
                  </div>
                  <div className="flex justify-between mb-2">
                    <p>Discount</p>
                    <p className="text-green-500">₹{0}</p>
                  </div>
                  <div className="flex justify-between mb-4">
                    <p>Delivery Charges</p>
                    <p className="text-green-500">Free</p>
                  </div>
                  <hr className="my-4" />
                  <div className="flex justify-between text-lg font-semibold">
                    <p>Total</p>
                    <p>₹{totalPrice}</p>
                  </div>
                  <p className="mt-4">Cart Items: {totalQuantityItem}</p>
                  <Link to="/order_summary">
                    <button className="w-full py-2 bg-orange-400 text-white rounded-lg mt-6 hover:bg-orange-500 transition">
                      Check Out
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className=" shadow-lg rounded-lg">
            <img
              src={`${process.env.REACT_APP_NODE_CATEGORY}uploads/campaign/${second.image}`}
              alt=""
              className=" p-5 w-full h-[230px]"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
