import axios from "axios";
import React, { useEffect, useState } from "react";
import UserAccount from "./UserAccount";
import Payment from './Payment'
// import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
// import { setUserProfile } from "./../../../redux/action";
import { MdMenu, MdOutlineFileCopy } from "react-icons/md";
import IdCard from "../../images/IdCard.jpg";

import { useRef } from "react";
import { toPng } from "html-to-image";
import { IoCloudDownloadOutline } from "react-icons/io5";

const ProfileInformation = () => {
  const divRef = useRef(null);
  
  const [isUpdating, setIsUpdating] = useState(false);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [userAccount, setUserAccount] = useState(true);
  const [userProfile, setUserProfiled] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [picture, setPicture] = useState(null);
  // const [profile_picture]
  const [refral, setRefral] = useState("");
  const [copied, setCopied] = useState(false);
  const [time, setTime] = useState("");
  const [link, setLink] = useState("");
  const [mobile, setMobile] = useState("");
  const dispatch = useDispatch();

  const userProfilee = useSelector((state) => state.userProfile);
  // console.log("userProfilee",userProfilee);
  const setUserProfile = async () => {
    if (!userId || !token) {
      console.error("User ID or token is missing");
      return; // Prevent the request if userId or token is missing
    }

    try {
 
      const response = await axios.get(
        `${process.env.REACT_APP_NODE}user/profile/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);

      const Data = response?.data?.data;
   
      setUserProfiled({
        firstName: Data.first_name,
        lastName: Data.last_name,
        email: Data.email,
      });
      setPicture(Data?.profile_picture);
      setMobile(Data.mobile_number);
      
      // console.table("profile details is ", response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized: Invalid token or session expired.");
        // Optional: dispatch a logout or token refresh action
      } else {
        console.error("Error fetching user profile:", error);
      }
    }
  };
  useEffect(() => {
    setUserProfile();
    async function checkReferralStatus(){
      try{
        const response=await axios.get(`/user/referralActive/${userId}`);
        const data=response.data;
        console.log(data);
        if(data.refferalStatus){
          const responseRefral = await axios.get(
            `${process.env.REACT_APP_NODE}refferal/user/${userId}`
          );
          console.log("referal linnk ", responseRefral.data.Data[0]);
          setRefral(responseRefral.data.Data[0]);
          setLink(responseRefral.data.Data[0].referral_link.slice(0, 60));
          setTime(
            new Date(responseRefral.data.Data[0].created_at)
              .toISOString()
              .split("T")[0]
          );
        }
      }catch(err){
        console.log(err);
      }
    }
    checkReferralStatus();
  }, []);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();

    if (!userId || !token) {
      console.error("User ID or token is missing");
      return;
    }
    try {
      setIsUpdating(true);
      const formData = new FormData();
      formData.append("firstName", userProfile.firstName);
      formData.append("lastName", userProfile.lastName);
      formData.append("email", userProfile.email);
      if (picture) {
        formData.append("profile_picture", picture);
      }

      const response = await axios.put(
        `${process.env.REACT_APP_NODE}user/profile/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      // console.log("Profile updated successfully:", response.data);
      // window.location.reload();
      setIsUpdating(false);
      setUserProfile();
    } catch (error) {
      console.error("Error updating profile:", error);
      setIsUpdating(false);
    }
  };

  function UserAcount() {
    setUserAccount(!userAccount);
  }
  function setValue(e) {
    setUserProfiled({
      ...userProfile,
      [e.target.id]: e.target.value,
    });
    console.log(e.target.id);
  }
  const handleCopy = () => {
    navigator.clipboard.writeText(refral.referral_link);
    setCopied(true);
    setTimeout(() => setCopied(false), 5000); // Reset after 2 seconds
  };

  const handleSendLink = (referlink) => {
    // Get the referral link from your data
    const message = `Check out this amazing referral offer! Use my referral code: ABC123. Join here: ${referlink}`;

    // Ensure you're using the referral link in both share and fallback methods
    if (navigator.share) {
      // Use Web Share API if supported
      navigator
        .share({
          title: "Referral Invitation",
          text: message,
          url: referlink, // Ensure referral link is used here
        })
        .then(() => console.log("Share successful!"))
        .catch((err) => console.error("Error sharing:", err));
    } else {
      // Fallback: Open WhatsApp and Gmail links
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
      const mailtoUrl = `mailto:?subject=Referral Invitation&body=${encodeURIComponent(
        message
      )}`;

      // Open WhatsApp and Gmail in new tabs or windows
      window.open(whatsappUrl, "_blank");
      window.open(mailtoUrl, "_blank");
    }
  };
  const handleDownload = async () => {
    if (divRef.current) {
      try {
        console.log("Div reference:", divRef.current); // Log the div
        const dataUrl = await toPng(divRef.current);
        console.log("Generated Image Data URL:", dataUrl); // Log the image data URL
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `${userProfile.firstName}_ID_Card.png`; // File name
        link.click();
      } catch (error) {
        console.error("Error generating image:", error); // Log the error if it fails
      }
    } else {
      console.error("Div reference is null"); // Log if divRef is not attached
    }
  };

  console.log(userProfile);

  return (
    <div className="relative bg-gray-100 flex gap-4">
      <div>
        {userAccount ? <UserAccount showUserAccount={UserAcount} /> : null}
      </div>

      <div className="flex-grow max-w-full mx-auto rounded-md bg-white p-8 mt-4">
        <div className="flex justify-end">
          <MdMenu
            onClick={UserAcount}
            className="absolute top-8 right-4 text-4xl cursor-pointer border rounded-lg px-1"
          />
        </div>

        <div className="flex items-center justify-between mx-4">
          <h2 className="text-xl font-semibold py-4">Personal Information</h2>
        </div>
        <div className="flex sm:gap-4 lg:gap-10 max-sm:flex-col-reverse">
          <div className="w-1/2 max-sm:w-full max-sm:mt-8 flex justify-center">
            <form className="p-4 rounded" onSubmit={handleProfileUpdate}>
              {/* Name Fields */}
              <div className="mb-6 flex flex-col md:flex-row md:gap-4">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-gray-700 mb-2"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    placeholder="First Name"
                    value={userProfile.firstName}
                    className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                    onChange={setValue}
                  />
                </div>

                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-gray-700 mb-2"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    value={userProfile.lastName}
                    className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                    onChange={setValue}
                  />
                </div>
              </div>

              {/* Gender Selection */}
              {/* <div className="mb-6">
            <label className="block text-gray-700 mb-2">Your Gender</label>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="male"
                name="gender"
                value="male"
                className="mr-2"
                required
              />
              <label htmlFor="male" className="text-gray-700 mr-4">
                Male
              </label>

              <input
                type="radio"
                id="female"
                name="gender"
                value="female"
                className="mr-2"
                required
              />
              <label htmlFor="female" className="text-gray-700">
                Female
              </label>
            </div>
          </div> */}

              <div className="mb-6">
                <label htmlFor="lastName" className="block text-gray-700 mb-2">
                  Profile Picture
                </label>
                <input
                  type="file"
                  id="profilePicture"
                  placeholder="Profile Picture"
                  onChange={(e) => setPicture(e.target.files[0])}
                  className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Email Address Field */}
              <div className="mb-6">
                <label htmlFor="email" className="block text-gray-700 mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email Address"
                  value={userProfile.email}
                  className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                  onChange={setValue}
                />
              </div>

              {/* Mobile Number Field */}
              <div className="mb-6">
                <label htmlFor="mobile" className="block text-gray-700 mb-2">
                  Mobile Number
                </label>
                <input
                  type="tel"
                  id="mobile"
                  value={mobile}
                  placeholder="Mobile Number"
                  className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              {/* Update Button */}
              <button
                type="submit"
                className="mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
              >
                Update Profile
              </button>
            </form>
          </div>
          <div
            ref={divRef}
            className="bg-cover bg-center border h-[400px] w-[400px] relative"
            style={{
              backgroundImage: `url(${IdCard})`,
            }}
          >
            <div
              className="absolute bg-purple-500 -bottom-8 right-10 left-10 rounded-lg"
              onClick={handleDownload}
            >
              <div className="flex justify-center items-center w-full">
                <IoCloudDownloadOutline className="text-white font-bold" />
                <p className="text-white p-1">DownLoad Card</p>
              </div>
            </div>

            <div className="flex items-center">
              <img
                src={require("../../images/macrolifelogo3.png")}
                alt="logo"
                className="w-[70px] h-[55px]"
              />
              <p className=" flex items-center text-white ml-2 font-bold pb-4 text-[20px]">
                OurMicroLife
              </p>
              <div></div>
            </div>

            <div>
              <div className="pl-20">
                <div className="w-28 h-28 rounded-full flex  justify-center ">
                  <img
                    src={`${process.env.REACT_APP_NODE_CATEGORY}uploads/userProfile/${picture}`}
                    alt="Profile"
                    className="w-28 h-28 rounded-full object-cover "
                  />
                </div>
              </div>

              <div className="pt-[100px] pl-[30px]">
                <div className="flex gap-2">
                  <p>Name: </p>
                  <p>
                    {userProfile.firstName} {userProfile.lastName}
                  </p>
                </div>
                <div className="flex gap-2">
                  <p>Number: </p>
                  <p>{mobile}</p>
                </div>
                {refral !== "" ? (
                  <div className="flex gap-2">
                    <p>Referal Code: </p>
                    <p>{refral.referral_code}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="w-1/2 max-sm:w-full">
            {isUpdating && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                <div className="bg-white p-6 rounded-lg shadow-xl w-80 text-center">
                  <div className="flex flex-col items-center">
                    <div className="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-green-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <h3 className="text-xl font-semibold text-gray-800 mt-4">
                      Success
                    </h3>
                    <p className="text-sm text-gray-600 mt-2">
                      Your profile is being updated. Please wait while we
                      process your request.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {refral !=="" ? (
              <div
                className="border-2 w-full max-w-[600px] p-6 rounded-lg shadow-xl bg-gradient-to-br from-white via-gray-100 to-gray-300 mx-auto"
                style={{ fontFamily: "Arial, sans-serif" }}
              >
                <h2 className="text-lg md:text-xl font-extrabold mb-4 text-gray-900 text-center">
                  🎉 Referral Details
                </h2>

                <div className="mb-4">
                  <span className="block font-semibold text-gray-700 mb-2">
                    Referral Link:
                  </span>
                  <span
                    className="text-black bg-gray-300 p-2 rounded-sm cursor-pointer break-all inline-block"
                    onClick={handleCopy}
                    title="Click to copy referral link"
                  >
                    {link}...
                    {copied ? (
                      <span className="text-green-500 ml-2">Copied!</span>
                    ) : (
                      <MdOutlineFileCopy className="h-5 w-5 inline-block ml-2 hover:text-purple-500" />
                    )}
                  </span>
                </div>

                <div className="mb-4 flex flex-col md:flex-row gap-2">
                  <span className="block font-semibold text-gray-700">
                    Created At:
                  </span>
                  <span className="text-gray-900">{time}</span>
                </div>

                <div className="mb-4 flex flex-col md:flex-row gap-2">
                  <span className="block font-semibold text-gray-700">
                    Referral Code:
                  </span>
                  <span className="text-gray-900 font-mono bg-gray-100 p-1 rounded-sm">
                    {refral.referral_code}
                  </span>
                </div>

                <div className="mb-4 flex flex-col md:flex-row gap-2">
                  <span className="block font-semibold text-gray-700">
                    Status:
                  </span>
                  <span className="text-sm text-white bg-green-500 px-2 py-1 rounded-full font-bold shadow-md inline-block">
                    {refral.referral_status}
                  </span>
                </div>

                <button
                  className="w-full mt-4 bg-purple-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-purple-700 transition duration-300"
                  onClick={() => handleSendLink(refral.referral_link)}
                >
                  Invite
                </button>
              </div>
            ) : (
              <Payment/>
            )}
          </div>
        </div>

        {/* Account Deletion Warning */}
        <div className="mb-4 mt-5">
          <p className="text-gray-600">
            Deleting your account will remove all your data permanently.
          </p>
          <button
            type="button"
            className="mt-2 text-red-600 hover:underline"
            // onClick={handleDeleteAccount}
          >
            Delete Account
          </button>
        </div>

        {/* FAQs Section */}
        <h2 className="text-xl font-bold mt-10">FAQs</h2>
        <div className="mt-4">
          <div className="mb-4">
            <h3 className="font-semibold">
              What happens when I update my email address (or mobile number)?
            </h3>
            <p className="text-gray-600">
              Your login email id (or mobile number) changes, likewise. You'll
              receive all your account-related communication on your updated
              email address (or mobile number).
            </p>
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">
              When will my Ourmicrolife account be updated with the new email
              address (or mobile number)?
            </h3>
            <p className="text-gray-600">
              It happens as soon as you confirm the verification code sent to
              your email (or mobile) and save the changes.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">
              What happens to my existing Ourmicrolife account when I update my
              email address (or mobile number)?
            </h3>
            <p className="text-gray-600">
              Updating your email address (or mobile number) doesn't invalidate
              your account. Your account remains fully functional. You'll
              continue seeing your order history, saved information, and
              personal details.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="font-semibold">
              Does my Seller account get affected when I update my email
              address?
            </h3>
            <p className="text-gray-600">
              Ourmicrolife has a 'single sign-on' policy. Any changes will
              reflect in your Seller account also.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInformation;