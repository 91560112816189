import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const PaymentCard = () => {
  const userId=localStorage.getItem('userId')
  const navigate=useNavigate();
  async function handlePay(){
    console.log('hello is working')
      try{  
        const response=await axios.post(`${process.env.REACT_APP_NODE}/user/mlmMembers/${userId}`)
        console.log(response);
        navigate('/welcome_congratulations')
      }catch(err){

        console.error('Payment failed', err);
      }
    }
  return (<>
    <div className="flex flex-col items-center justify-center p-6 bg-white rounded-2xl shadow-lg w-80 border w-[100%]">
      <img src="https://m.media-amazon.com/images/S/stores-image-uploads-na-prod/6/AmazonStores/ATVPDKIKX0DER/1801cf91b2805c36abff373d2ab9d5ec.w600.h600._RO299,1,0,0,0,0,0,0,0,0,15_FMpng_.png" alt="Logo" className="w-16 h-16" />
      <h2 className="mt-4 text-xl font-bold text-gray-800">Unlock MLM Benefits</h2>
            <p className="text-gray-600 text-base mt-2 px-4 leading-relaxed">
              Be a part of <span className="font-bold text-purple-700">MLM</span> by paying <span className="font-bold text-purple-700">₹500</span>.
            </p>
            <div className="w-full bg-gray-100 p-4 rounded-lg mt-4">
              <p className="text-gray-600 text-center">Amount</p>
              <h3 className="text-2xl font-bold text-purple-600 text-center">₹500</h3>
            </div>
         
          <button onClick={handlePay} className="mt-6 w-full bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white py-3 rounded-lg text-lg font-semibold shadow-md transition-transform transform hover:scale-105">
            Proceed to Pay
          </button>
          <div className="mt-4 text-xs text-gray-500 mb-[10px]">Secure payment with multiple options available</div>
    </div>
    </>);
};

export default PaymentCard;
