import React, { useEffect } from "react";

import Categories from "../components/Categories";
import Carousel from "../components/Carousel";
import ProductCard from "../components/ProductCard";
import Carousel2 from "./../components/Carousel2";
import ProductCardTwo from "../components/ProductCardTwo";
import BrandsInFocus from "../components/BrandsInFocus";

import TrendingOffer from "../components/TrendingOffers";
import SuresopSetting from "../components/SuresopSetting";
import ImageGallery from "../components/ImageGallery";
import ImageGalleryTwo from "../components/ImageGallaryTwo";
import TeamCarousel from "../components/TeamCarousel";
import BrandCarousel from "../components/BrandCarousel";

const Home = () => {
  useEffect(()=>{
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "instant",
    });    
  },[])
  return (
    <>
      <Categories />
      <Carousel />
      <ProductCard />
      <BrandCarousel />
      {/* <TeamCarousel /> */}
      <ImageGallery />
      <Carousel2 />
      <ProductCardTwo />
      <BrandsInFocus />
      <ImageGalleryTwo />
      <BrandCarousel />
      {/* <TrendingOffer /> */}
      <SuresopSetting />
    </>
  );
};

export default Home;
