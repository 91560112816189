import React, { useState } from "react";
import UserAccount from "./UserAccount";
const levels = [
  "Sahyogi", "Promoter", "Distributer", "Silver", "Gold", "Ruby", "Pearl", "Emerald", "Topaz",
  "Sapphire", "Citrine", "Zircon", "Spinal", "Diamond", "Director"
];

const rewards = [
  { level: "1st", position: "100 coins / software charge", award: "Sahyogi", reward: "XXXX", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "2nd", position: "5 Sahyogi", award: "Promoter", reward: "Ex. Dairy", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "3rd", position: "5 Promoter", award: "Distributer", reward: "Ex. Bag", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "4th", position: "5 Distributer", award: "Silver", reward: "Table Fan", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "5th", position: "5 Silver", award: "Gold", reward: "Well Watch", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "6th", position: "5 Gold", award: "Ruby", reward: "Mobile", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "7th", position: "5 Ruby", award: "Pearl", reward: "Smart Phone", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "8th", position: "5 Pearl", award: "Emerald", reward: "CCTV", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "9th", position: "5 Emerald", award: "Topaz", reward: "Laptop", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "10th", position: "5 Topaz", award: "Sapphire", reward: "Bike", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "11th", position: "5 Sapphire", award: "Citrine", reward: "Bullet", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "12th", position: "5 Citrine", award: "Zircon", reward: "Wegon R", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "13th", position: "5 Zircon", award: "Spinal", reward: "Scorpio", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "14th", position: "5 Spinal", award: "Diamond", reward: "Fortuner", photo: "https://pngimg.com/d/book_PNG2111.png" },
  { level: "15th", position: "5 Diamond", award: "Director", reward: "Luxurious House", photo: "https://pngimg.com/d/book_PNG2111.png" },
];

const YourLevel = () => {
    const [userAccount, setUserAccount] = useState(true);
  const currentProgress = 15;
  const [selectedLevel, setSelectedLevel] = useState("All");

  const filteredRewards = selectedLevel === "All" ? rewards : rewards.filter(item => item.award === selectedLevel);

  return (
    <div  className="flex gap-4 " >
       <div>{userAccount ? <UserAccount /> : null}</div>
    <div className="p-4  w-[100%] mx-auto">
      <div className="bg-white shadow-lg rounded-lg p-4 mb-6">
        <h2 className="text-lg font-semibold">Your level</h2>
        <div className="relative w-full h-2 bg-gray-200 rounded-full mt-2">
          <div
            className="absolute left-0 top-0 h-2 bg-green-500 rounded-full"
            style={{ width: `${currentProgress}%` }}
          ></div>
          <div className="absolute top-[-6px] w-full flex justify-between">
            {levels.map((_, index) => (
              <div
                key={index}
                className={`w-4 h-4 rounded-full border-2 border-green-500 bg-white ${
                  index * (100 / (levels.length - 1)) <= currentProgress ? "bg-green-500" : ""
                }`}
              ></div>
            ))}
          </div>
        </div>
        <div className="flex justify-between text-xs text-gray-600 mt-1">
          {levels.map((level, index) => (
            <span key={index}>{level}</span>
          ))}
        </div>
      </div>
      <div className="bg-white shadow-lg rounded-lg p-4">
        <div className="flex justify-between items-center bg-purple-600 text-white p-2 rounded-t-lg">
          <h2 className="text-lg font-semibold">Your reward on position</h2>
          <select
            className="bg-white text-purple-600 px-3 py-1 rounded-md text-sm"
            value={selectedLevel}
            onChange={(e) => setSelectedLevel(e.target.value)}
          >
            <option value="All">All rewards</option>
            {levels.map((level, index) => (
              <option key={index} value={level}>{level}</option>
            ))}
          </select>
        </div>
        <table className="w-full border border-gray-300 overflow-hidden">
          <thead className="bg-purple-400 text-white">
            <tr>
              <th className="p-2 border">Level</th>
              <th className="p-2 border">Current Position</th>
              <th className="p-2 border">Award</th>
              <th className="p-2 border">Reward</th>
              <th className="p-2 border">Photo</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {filteredRewards.map((item, index) => (
              <tr key={index} className={`text-center border ${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
                <td className="p-2 border font-semibold bg-purple-400 text-white">Level {item.level}</td>
                <td className="p-2 border">{item.position}</td>
                <td className="p-2 border">{item.award}</td>
                <td className="p-2 border">{item.reward}</td>
                <td className="p-2 border">
                  <img src={item.photo} alt="Reward" className="w-8 h-8 mx-auto" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};

export default YourLevel;
